import React, { Suspense, lazy } from "react"
import { Navigate } from "react-router-dom"
import { Spinner } from "reactstrap"
import Layout from "components/Layout"
import Authmiddleware from "./route"
import roleTypes from "constants/role"

// eslint-disable-next-line react/display-name
const Loadable = Component => props =>
  (
    <Suspense fallback={<Spinner color="primary">Loading...</Spinner>}>
      <Component {...props} />
    </Suspense>
  )

// ---------------------------- AUTHENTICATION PAGES ----------------------------
const Authentication = Loadable(
  lazy(() => import("../pages/Authentication/AutoLogin"))
);
const OtpLoginVerfication= Loadable(
  lazy(() => import("../pages/Authentication/OtpLoginVerfication"))
);
const PsLogin = Loadable(lazy(() => import("../pages/Authentication/PsLogin")))
const Login = Loadable(lazy(() => import("pages/Authentication/Login")))
const Logout = Loadable(lazy(() => import("pages/Authentication/Logout")))
const Register = Loadable(lazy(() => import("pages/Authentication/Register")))
const ForgetPwd = Loadable(
  lazy(() => import("pages/Authentication/ForgetPassword"))
)

// ---------------------------- ERROR PAGES ----------------------------
const ForbiddenError = Loadable(
  lazy(() => import("pages/Utility/ErrorUnauthorized"))
)
const NotFoundError = Loadable(
  lazy(() => import("pages/Utility/ErrorNotFound"))
)
const ServerError = Loadable(lazy(() => import("pages/Utility/ErrorServer")))

// ------------------------ ADMIN PORTAL PAGES ------------------------
const AdminUsers = Loadable(lazy(() => import("pages/Admin/Users/Admins")))
const AgencyUsers = Loadable(lazy(() => import("pages/Admin/Users/Agencies")))
const AdminDashboard = Loadable(lazy(() => import("pages/Admin/Dashboard")))
// const Emarketplace = Loadable(lazy(() => import("pages/Admin/EMarketPlace")))
const DefineProductSpecifications = Loadable(
  lazy(() => import("pages/Admin/ItemMaster/DefineProductSpecifications"))
)
const ItemSpecificationList = Loadable(
  lazy(() => import("pages/Admin/ItemMaster/ItemSpecificationList"))
)
const OtherFeesList = Loadable(
  lazy(() => import("pages/Admin/ItemMaster/OtherFeesList"))
)

const DefineOtherFees = Loadable(
  lazy(() => import("pages/Admin/ItemMaster/DefineOtherFees"))
)
const ViewOtherFees = Loadable(
  lazy(() => import("pages/Admin/ItemMaster/ViewOtherFees"))
)
const EditOtherFees = Loadable(
  lazy(() => import("pages/Admin/ItemMaster/EditOtherFees"))
)

const ConsignorList = Loadable(
  lazy(() => import("pages/Admin/Consignor/ConsignorList"))
)

const AddConsignor = Loadable(
  lazy(() => import("pages/Admin/Consignor/AddConsignor"))
)

const EditConsignor = Loadable(
  lazy(() => import("pages/Admin/Consignor/EditConsignor"))
)

const RenewalConsignor = Loadable(
  lazy(() => import("pages/Admin/Consignor/RenewalConsignor"))
)

const ViewConsignor = Loadable(
  lazy(() => import("pages/Admin/Consignor/ViewConsignor"))
)

const MerchantUsers = Loadable(
  lazy(() => import("pages/Admin/Users/Merchants"))
)
const SubCategories = Loadable(
  lazy(() => import("pages/Admin/Products/SubCategories"))
)
const SubSubCategory = Loadable(
  lazy(() => import("pages/Admin/Products/SubSubCategories"))
)
const ViewPendingProduct = Loadable(
  lazy(() => import("pages/Admin/ProductListing/ViewPendingProduct"))
)
const ParentCategories = Loadable(
  lazy(() => import("pages/Admin/Products/ParentCategories"))
)
const Category = Loadable(
  lazy(() => import("pages/Admin/Products/ParentCategories/Category"))
)
const SubCategory = Loadable(
  lazy(() => import("pages/Admin/Products/SubCategories/SubCategory"))
)
const ViewSubSubCategory = Loadable(
  lazy(() => import("pages/Admin/Products/SubSubCategories/SubSubCategory"))
)
const TableListing = Loadable(
  lazy(() => import("pages/Admin/ProductListing/TableListing"))
)
const PublishedListing = Loadable(
  lazy(() => import("pages/Admin/ProductListing/PublishedListing"))
)
const PendingOrder = Loadable(
  lazy(() => import("pages/Admin/AgencyOrders/PendingOrder"))
)
const ViewPendingOrder = Loadable(
  lazy(() => import("pages/Admin/AgencyOrders/PendingOrder/ViewPendingOrder"))
)
const ConfirmedOrder = Loadable(
  lazy(() => import("pages/Admin/AgencyOrders/ConfirmedOrder"))
)
const ViewConfirmedOrder = Loadable(
  lazy(() =>
    import("pages/Admin/AgencyOrders/ConfirmedOrder/ViewConfirmedOrder")
  )
)
const AdminPayments = Loadable(lazy(() => import("pages/Admin/Payments")))

const GeneratePaymentsReport = Loadable(
  lazy(() => import("pages/Admin/GeneratePaymentsReport"))
)

const AdminNotifications = Loadable(
  lazy(() => import("pages/Admin/Notifications/AdminNotifications"))
)

// ------------------------ MERCHANT PORTAL PAGES ------------------------
const MerchantDashboard = Loadable(
  lazy(() => import("pages/Merchant/Dashboard/index"))
)
const MerchantProducts = Loadable(
  lazy(() => import("pages/Merchant/Products/MyProducts"))
)
const DraftItems = Loadable(
  lazy(() => import("pages/Merchant/Products/DraftItems"))
)
const PendingForApprovalProducts = Loadable(
  lazy(() => import("pages/Merchant/Products/PendingForApproval"))
)
const PublishedProducts = Loadable(
  lazy(() => import("pages/Merchant/Products/Published"))
)
const InactiveProducts = Loadable(
  lazy(() => import("pages/Merchant/Products/Inactive"))
)
const AddProduct = Loadable(
  lazy(() => import("pages/Merchant/Products/AddProduct"))
)
const MerchantEditProduct = Loadable(
  lazy(() => import("pages/Merchant/Products/EditProduct"))
)
const MerchantViewProduct = Loadable(
  lazy(() => import("pages/Merchant/Products/ViewProduct"))
)
const MerchantPendingOrder = Loadable(
  lazy(() => import("pages/Merchant/Products/PendingOrder"))
)
const MerchantViewPendingOrder = Loadable(
  lazy(() => import("pages/Merchant/Products/PendingOrder/ViewPendingOrder"))
)
const ActiveOrders = Loadable(
  lazy(() => import("pages/Merchant/Orders/active-orders/ActiveOrders"))
)
const ViewActiveOrderDetails = Loadable(
  lazy(() =>
    import("pages/Merchant/Orders/active-orders/ViewActiveOrderDetails")
  )
)

const OrderHistory = Loadable(
  lazy(() => import("pages/Merchant/Orders/OrderHistory"))
)
const ViewOrderHistoryDetails = Loadable(
  lazy(() => import("pages/Merchant/Orders/ViewOrderHistoryDetails"))
)

const ReturnedOrders = Loadable(
  lazy(() => import("pages/Merchant/Orders/returned-orders/ReturnedOrders"))
)
const ViewReturnedOrderDetails = Loadable(
  lazy(() =>
    import("pages/Merchant/Orders/returned-orders/ViewReturnedOrderDetails")
  )
)
const Payments = Loadable(lazy(() => import("pages/Merchant/Payments")))

const MerchantNotifications = Loadable(
  lazy(() => import("pages/Merchant/Notifications/MerchantNotifications"))
)

// ------------------------ AGENCY PORTAL PAGES ------------------------
const AgencyHome = Loadable(lazy(() => import("pages/Agency/Home")))
const AgencyCategory = Loadable(lazy(() => import("pages/Agency/Category")))
const AgencyCompareItems = Loadable(
  lazy(() => import("pages/Agency/CompareItems"))
)
const AgencyPrintCompareItems = Loadable(
  lazy(() => import("pages/Agency/CompareItems/PrintCompare"))
)
const AgencyMyCart = Loadable(lazy(() => import("pages/Agency/MyCart")))
const AgencyCheckOut = Loadable(
  lazy(() => import("pages/Agency/CheckOut/CheckOut"))
)
const AgencyMyOrders = Loadable(lazy(() => import("pages/Agency/MyOrders")))
const AgencyOrderStatus = Loadable(
  lazy(() => import("pages/Agency/OrderStatus/OrderStatus"))
)
const AgencyViewProduct = Loadable(
  lazy(() => import("pages/Agency/ViewProduct"))
)

const AgencyMyProfile = Loadable(
  lazy(() => import("pages/Agency/Profile/MyProfile"))
)
const AgencyEditProfile = Loadable(
  lazy(() => import("pages/Agency/Profile/EditProfile"))
)
const AgencyUpdatePassword = Loadable(
  lazy(() => import("pages/Agency/ChangePassword/UpdatePassword"))
)
const Ordering = Loadable(
  lazy(() => import("pages/Agency/OrderStatus/Ordering"))
)
const Transaction = Loadable(
  lazy(() => import("pages/Agency/Transaction/index"))
)

const AgencyNotifications = Loadable(
  lazy(() => import("pages/Agency/Notifications/AgencyNotification"))
)

const authProtectedRoutes = [
  // ADMIN ROUTES ------------------------------
  {
    path: "admin",
    element: (
      <Authmiddleware role={roleTypes["ADMIN"]}>
        <Layout />
      </Authmiddleware>
    ),
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "item-master",
        children: [
          {
            path: "item-specification",
            element: <ItemSpecificationList />,
          },
          {
            path: "item-specification/create",
            element: <DefineProductSpecifications type="Create" />,
          },
          {
            path: "item-specification/:id/edit",
            element: <DefineProductSpecifications type="Edit" />,
          },
          {
            path: "item-specification/:id/view",
            element: <DefineProductSpecifications type="View" />,
          },
        ],
      },
      {
        path: "other-fees",
        children: [
          {
            index: true,
            element: <OtherFeesList />,
          },
          {
            path: "create",
            element: <DefineOtherFees type="Create" />,
          },
          {
            path: ":id/view",
            element: <ViewOtherFees type="View" />,
          },
          {
            path: ":id/edit",
            element: <EditOtherFees type="Edit" />,
          },
        ],
      },
      {
        path: "consignor",
        children: [
          {
            index: true,
            element: <ConsignorList />,
          },
          {
            path: "create",
            element: <AddConsignor type="Create" />,
          },
          {
            path: ":id/view",
            element: <ViewConsignor type="View" />,
          },
          {
            path: ":id/edit",
            element: <EditConsignor type="Edit" />,
          },
          {
            path: ":id/renewal",
            element: <RenewalConsignor type="Renewal" />,
          },
        ],
      },
      {
        path: "product-listing-pending",
        element: <TableListing type="Pending" />,
      },
      {
        path: "product-listing-published",
        element: <PublishedListing type="published" />,
      },
      {
        path: "product-listing/:id",
        element: <ViewPendingProduct />,
      },
      {
        path: "pending-order",
        element: <PendingOrder />,
      },
      {
        path: "pending-order/:id",
        element: <ViewPendingOrder />,
      },
      {
        path: "confirmed-order",
        element: <ConfirmedOrder />,
      },
      {
        path: "confirmed-order/:id",
        element: <ViewConfirmedOrder />,
      },
      {
        path: "payments-list",
        element: <AdminPayments />,
      },
      {
        path: "payment-reports",
        element: <GeneratePaymentsReport />,
      },
      {
        path: "parent-categories",
        children: [
          {
            index: true,
            element: <ParentCategories />,
          },
          {
            path: "add",
            element: <Category type="Add" />,
          },
          {
            path: ":id",
            element: <Category type="Edit" />,
          },
        ],
      },
      {
        path: "sub-categories",
        children: [
          {
            index: true,
            element: <SubCategories />,
          },
          {
            path: "add",
            element: <SubCategory type="Add" />,
          },
          {
            path: ":id",
            element: <SubCategory type="Edit" />,
          },
        ],
      },
      {
        path: "sub-sub-categories",
        children: [
          {
            index: true,
            element: <SubSubCategory />,
          },
          {
            path: "add",
            element: <ViewSubSubCategory type="Add" />,
          },
          {
            path: ":id",
            element: <ViewSubSubCategory type="Edit" />,
          },
        ],
      },
      {
        // 2nd path
        path: "notifications",
        element: <AdminNotifications />,
      },
    ],
  },

  // MERCHANT ROUTES -------------------------------
  {
    // 1st path
    path: "merchant",
    element: (
      <Authmiddleware role={roleTypes["MERCHANT"]}>
        <Layout />
      </Authmiddleware>
    ),
    children: [
      {
        // 2nd path
        path: "dashboard",
        element: <MerchantDashboard />,
      },
      {
        path: "pending-order",
        element: <MerchantPendingOrder />,
      },
      {
        path: "pending-order/:id",
        element: <MerchantViewPendingOrder />,
      },
      {
        // 2nd path
        path: "products",
        children: [
          {
            path: "add",
            element: <AddProduct />,
          },
          {
            path: ":id/view",
            element: <MerchantViewProduct />,
          },
          {
            path: ":id/edit",
            element: <MerchantEditProduct />,
          },
        ],
      },
      {
        path: "products-draft-item",
        element: <DraftItems />,
      },
      {
        path: "products-for-approval",
        element: <PendingForApprovalProducts />,
      },
      {
        path: "products-published",
        element: <PublishedProducts />,
      },
      {
        path: "products-inactive",
        element: <InactiveProducts />,
      },
      {
        // 2nd path
        path: "active-orders-list",
        children: [
          {
            // Child Direct Page Component of /merchant/orders
            index: true,
            element: <ActiveOrders />,
          },
          // Grandchild or 3rd path
          {
            path: "view-active-order-details",
            element: <ViewActiveOrderDetails />,
          },
        ],
      },
      {
        // 2nd path
        path: "order-history-list",
        children: [
          {
            // Child Direct Page Component of /merchant/orders
            index: true,
            element: <OrderHistory />,
          },
          // Grandchild or 3rd path
          {
            path: "view-order-history-details",
            element: <ViewOrderHistoryDetails />,
          },
        ],
      },
      {
        // 2nd path
        path: "payments-list",
        children: [
          {
            // Child Direct Page Component of /merchant/orders
            index: true,
            element: <Payments />,
          },
        ],
      },
      // {
      //   // 2nd path
      //   path: "returned-orders-list",
      //   children: [
      //     {
      //       // Child Direct Page Component of /merchant/orders
      //       index: true,
      //       element: <ReturnedOrders />,
      //     },
      //     // Grandchild or 3rd path
      //     {
      //       path: "view-returned-order-details",
      //       element: <ViewReturnedOrderDetails />,
      //     },
      //   ],
      // },
      {
        // 2nd path
        path: "notifications",
        element: <MerchantNotifications />,
      },
    ],
  },

  // AGENCY ROUTES ------------------------------
  {
    // 1st path
    path: "agency",
    element: (
      <Authmiddleware role={roleTypes["AGENCY"]}>
        <Layout />
      </Authmiddleware>
    ),
    children: [
      {
        // 2nd path
        path: "ewallet",
        element: <Transaction />,
      },
      {
        // 2nd path
        path: "home",
        element: <AgencyHome />,
      },
      {
        // 2nd path
        path: "category",
        element: <AgencyCategory />,
      },
      {
        // 2nd path
        path: "check-out",
        element: <AgencyCheckOut />,
      },
      {
        // 2nd path
        path: "compare-items",
        element: <AgencyCompareItems />,
      },
      {
        // 2nd path
        path: "compare-items/print",
        element: <AgencyPrintCompareItems />,
      },
      {
        // 2nd path
        path: "order-status/:id",
        element: <AgencyOrderStatus />,
      },
      {
        // 2nd path
        path: "order-status/:id/:id2",
        element: <AgencyOrderStatus />,
      },
      {
        // 2nd path
        path: "view-product/:id",
        element: <AgencyViewProduct />,
      },
      {
        // 2nd path
        path: "my-cart",
        element: <AgencyMyCart />,
      },
      {
        // 2nd path
        path: "my-orders",
        element: <AgencyMyOrders />,
      },
      {
        // 2nd path
        path: "my-profile",
        element: <AgencyMyProfile />,
      },
      {
        // 2nd path
        path: "edit-profile",
        element: <AgencyEditProfile />,
      },
      {
        // 2nd path
        path: "change-password",
        element: <AgencyUpdatePassword />,
      },
      {
        path: "order-processing/:id",
        element: <Ordering />,
      },
      {
        // 2nd path
        path: "notifications",
        element: <AgencyNotifications />,
      },
    ],
  },

  // WILD CARD (DEFAULT MAIN ROUTES) localhost:3000 or main endpt----
  {
    path: "*",
    children: [
      {
        path: "", // equivalent to 👉 / to fix react-router bug
        element: <Navigate to="/authenticate" />,
      },
      {
        path: "403",
        element: <ForbiddenError />,
      },
      {
        path: "404",
        element: <NotFoundError />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFoundError />,
      },
    ],
  },
  // PUBLIC ROUTES ------------------------------------------
  { path: "authenticate", element: <Authentication /> }, //AUTO LOGIN
  { path: "login", element: <Login /> },
  // { path: "login-otp-verification", element: <OtpLoginVerfication /> },
  // { path: "logout", element: <Logout /> },
  // { path: "login", element: <Login /> },
  // { path: "forgot-password", element: <ForgetPwd /> },
  // { path: "register", element: <Register /> },
]

export { authProtectedRoutes }
